@screen md {
  .reveal {
    position: relative;
    overflow: hidden;

    .reveal__inner {
      animation: fadeIn 0s 0.5s;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: revealInnerOut 1s 0.5s;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      background: theme("colors.brand-orange");
      content: "";
      transform: translateY(0%);
    }
  }

  .reveal--cream {
    &::after {
      background: theme("colors.brand-cream");
    }
  }

  .delay-1 {
    animation-delay: 1.25s;

    &::after {
      animation-delay: 1.25s;
    }
  }

  .delay-2 {
    animation-delay: 1.75s;

    &::after {
      animation-delay: 1.75s;
    }
  }

  .delay-3 {
    animation-delay: 2.25s;

    &::after {
      animation-delay: 2.25s;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes revealInnerOut {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.spin {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: spin;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
