h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @apply
    p-0
    mb-4
    leading-none
    font-sans
    uppercase
    tracking-tight;
}

h1,
.h1 {
  @apply text-3xl;

  @screen md {
    @apply text-4xl;
  }

  @screen xl {
    @apply text-5xl;
  }
}

h2,
.h2 {
  @apply text-2xl;

  @screen md {
    @apply text-3xl;
  }

  @screen xl {
    @apply text-4xl;
  }
}

h3,
.h3 {
  @apply text-xl;

  @screen md {
    @apply text-2xl;
  }

  @screen xl {
    @apply text-3xl;
  }
}


h4,
.h4 {
  @apply text-lg;

  @screen md {
    @apply text-xl;
  }

  @screen xl {
    @apply text-2xl;
  }
}

h5,
.h5,
h6 .h6 {
  @apply text-base;

  @screen md {
    @apply text-lg;
  }
}


.h-reset {
  font-size: inherit;

  @screen md {
    font-size: inherit;
  }

  @screen xl {
    font-size: inherit;
  }
}

.h--trim {
  @apply mb-0 pb-0;
}
